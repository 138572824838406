import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import CardList from '@components/CardList'
import Seo from '@widgets/Seo'
import { graphql } from 'gatsby'

const PagePublications = ({ data: { publications = {} }, ...props }) => {
  return (
    <Layout {...props}>
      <Seo title='Publications' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='Publications'
            subheader='Publications By Category'
          />
          {publications.group.length &&
            publications.group.map((group, index) => (
              <React.Fragment key={`${group.categoryName}.list`}>
                {index % 2 === 0 ? (
                  <Stack
                    title={group.categoryName}
                    titleLink={group.nodes[0].category.slug}
                  >
                    <Main>
                      <CardList
                        nodes={group.nodes}
                        variant={['horizontal-md', 'horizontal']}
                        limit={8}
                        columns={[1, 2, 2, 2]}
                        omitCategory
                      />
                    </Main>
                  </Stack>
                ) : (
                  <Stack
                    title={group.categoryName}
                    titleLink={group.nodes[0].category.slug}
                  >
                    <Main>
                      <CardList
                        nodes={group.nodes}
                        variant={['horizontal-md', 'horizontal']}
                        limit={8}
                        columns={[1, 1, 2]}
                        omitCategory
                      />
                    </Main>
                  </Stack>
                )}
                {index !== publications.group.length - 1 && <Divider />}
              </React.Fragment>
            ))}
        </Main>
      </Stack>
    </Layout>
  )
}

export default PagePublications
export const pageQuery = graphql`
  query PublicationsPageQuery(
    $paginatePostsPage: Boolean!
    $skip: Int
    $limit: Int
    $includeExcerpt: Boolean!
    $includeTimeToRead: Boolean!
    $imageQuality: Int!
  ) {
    publications: allArticle(
      filter: {
        featured: { eq: true }
        private: { ne: true }
        draft: { ne: true }
      }
      sort: { fields: [date], order: DESC }
      limit: 1000
    ) @skip(if: $paginatePostsPage) {
      group(field: category___name, limit: 10) {
        categoryName: fieldValue
        nodes {
          ...ArticlePreview
          ...ArticleThumbnailRegular
        }
      }
    }

    paginatedPublications: allArticle(
      filter: { private: { ne: true }, draft: { ne: true } }
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
    ) @include(if: $paginatePostsPage) {
      nodes {
        ...ArticlePreview
        ...ArticleThumbnailRegular
      }
      ...ArticlePagination
    }
  }
`
